<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">在校課程播放</h5>
      <div class="dtc-search1">
        <b-input-group prepend="單位">
          <b-select
            :options="schoolOptions"
            v-model="searchSchool"
            @change="getData"
            value-field="SchoolCode"
            text-field="SchoolName"
            :disabled="healthCodeCheck"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="類別">
          <b-select
            :options="branchOptions"
            v-model="searchBranch"
            value-field="Code"
            text-field="Display"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="課程主旨">
          <b-input
            v-model.trim="searchSubject"
            @keydown.enter="getData"
          ></b-input>
        </b-input-group>
        <!-- <b-input-group prepend="課程內容">
          <b-input
            v-model.trim="searchContent"
            @keydown.enter="getData"
          ></b-input>
        </b-input-group> -->

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>
    <div class="main-content">
      <!-- 左側區域：影片 -->
       <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
        <!-- <vue-plyr>
          <div class="plyr__video-embed">
            <iframe
              :src="singleCourseData.VideoUrl"
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>
          </div>
        </vue-plyr> -->
        <div class="sectoion-left">
          <div class="youtube-wrapper">
            <iframe
              :src="`https://www.youtube.com/embed/${videoId}?autoplay=0&enablejsapi=1`"
              frameborder="0"
              allowfullscreen
              style="width: 100%; height: 100%;"
            ></iframe>
          </div>
        </div>
      <!-- 右側區域：課程清單 -->
      <div class="sectoion-right">
        <header class="sectoion-right-head">
          課程清單 Curriculum List
        </header>
        <main class="sectoion-right-main">
          <div
            v-for="(itemcourse, i) in courseDatas"
            :key="`${i}${itemcourse}`"
            :title="itemcourse.Subject"
            @click="changeCourse(itemcourse)"
          >
            {{ itemcourse.Subject }}
          </div>
        </main>
      </div>
    </div>
    
    <div class="mt-2">
      <b-tabs
        active-nav-item-class="text-warning"
        content-class="mt-3"
        align="center"
      >
        <b-tab title="基本資料" active>
          <div class="course-line">
            <span class="course-line-head">
              <i class="far fa-circle"></i> 課程主旨: </span
            ><span class="course-line-content">{{
              singleCourseData.Subject || ""
            }}</span>
          </div>
          <div class="course-line">
            <span class="course-line-head">
              <i class="far fa-circle"></i> 發布單位: </span
            ><span class="course-line-content">{{
              singleCourseData.schoolName || ""
            }}</span>
          </div>
          <div class="course-line">
            <span class="course-line-head"
              ><i class="far fa-circle"></i> 影片類別: </span
            ><span class="course-line-content">{{
              singleCourseData.branchName || ""
            }}</span>
          </div>
          <div class="course-line">
            <span class="course-line-head">
              <i class="far fa-circle"></i> 影片開始日期: </span
            ><span class="course-line-content">{{
              singleCourseData.CreateTime
                ? $twDate2(singleCourseData.CreateTime)
                : ""
            }}</span>
          </div>
          <div class="course-line">
            <span class="course-line-head"
              ><i class="far fa-circle"></i> 影片下架日期: </span
            ><span class="course-line-content">{{
              singleCourseData.EndDate ? $twDate2(singleCourseData.EndDate) : ""
            }}</span>
          </div>
          <div class="course-line">
            <span class="course-line-head"
              ><i class="far fa-circle"></i> 發布者: </span
            ><span class="course-line-content">{{
              singleCourseData.Creater || ""
            }}</span>
          </div>
          <div class="course-line">
            <span class="course-line-head"
              ><i class="far fa-circle"></i> 影片連結: </span
            ><span class="course-line-content">{{
              singleCourseData.VideoUrl || ""
            }}</span>
          </div>
        </b-tab>
        <b-tab title="課程內容"
          ><p>{{ singleCourseData.Content }}</p></b-tab
        >
        <b-tab title="課程附件"
          ><div
            v-for="(itemFile, filekey) in singleCourseData.Files"
            :key="`${filekey}itemFile`"
          >
            <b-button
              variant="success"
              class="mt-2 mr-2"
              @click="openFile(itemFile)"
              ><i class="fas fa-file-alt"></i> 附件{{ filekey + 1 }}</b-button
            >
            <span style="padding-top:5px">
              {{ itemFile.DocFile.split("/").pop() }}</span
            >
          </div></b-tab
        >
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { store } from "@/store/global.js";

import "sweetalert2/dist/sweetalert2.min.css";

import { domainObject } from "@/constant.js";

export default {
  name: "downarea",
  data() {
    return {
      courseDatas: [],
      singleCourseData: {},

      yearNow: new Date().getFullYear() - 1911,

      searchSchool: null,
      searchBranch: null,
      searchSubject: "",
      searchContent: "",

      //searchOptions or ModalOptions
      schoolOptions: [],
      branchOptions: [],
      videoId: "",
      playerVars: {
        autoplay: 0,
      },
      domainObject,
    };
  },
  components: {},
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },

  methods: {
    openFile(item) {
      const url = `${this.domainObject.platformOriginal}${item.DocFile}`;

      window.open(url);
    },
    playing() {
      //   console.log("\o/ we are watching!!!");
    },
    getId(url) {
      return this.$youtube.getIdFromUrl(url);
    },
    clearSearch() {
      this.searchSchool = null;
      this.searchBranch = null;
      this.searchSubject = "";
      this.searchContent = "";
      this.getData();
    },
    async getData() {
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }
      const url = `Training/ActiveList`;
      let qs = `?$filter=Kind eq '1'`;
      let bQs = false;

      if (this.searchSchool) {
        qs = this.$appendSubstringofFilter("Code", qs, this.searchSchool);
      }

      if (this.searchBranch) {
        qs = this.$appendSubstringofFilter("Branch", qs, this.searchBranch);
      }

      if (this.searchSubject) {
        qs = this.$appendSubstringofFilter("Subject", qs, this.searchSubject);
      }

      if (this.searchContent) {
        qs = this.$appendSubstringofFilter("Content", qs, this.searchContent);
      }

      try {
        let { Items, Count } = await window.axios.get(url + qs);
        Items = Items.map((s) => {
          s.schoolName = this.schoolOptions.find((k) => k.SchoolCode == s.Code)
            ? this.schoolOptions.find((k) => k.SchoolCode == s.Code).SchoolName
            : "";
          s.branchName = this.branchOptions.find((k) => k.Code == s.Branch)
            ? this.branchOptions.find((k) => k.Code == s.Branch).Display
            : "";
          return s;
        });
        this.courseDatas = JSON.parse(JSON.stringify(Items));
        if (Items.length) {
          this.singleCourseData = JSON.parse(JSON.stringify(Items[0]));
          const vid = this.getId(this.singleCourseData.VideoUrl);
          this.videoId = vid ? vid : "";
          //console.log(this.videoId);
        }
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    changeCourse(item) {
      this.singleCourseData = JSON.parse(JSON.stringify(item));
      const vid = this.getId(this.singleCourseData.VideoUrl);
      this.videoId = vid ? vid : "";
    },
    async getOptions() {
      let schoolOptions = JSON.parse(localStorage.dtcSchoolList);
      schoolOptions.unshift({ SchoolCode: null, SchoolName: "全部" });

      this.schoolOptions = JSON.parse(JSON.stringify(schoolOptions));

      let { Items } = await window.axios.get(
        "api/SysCode?&$filter=Category eq 'branch'"
      );
      Items.unshift({ Code: null, Display: "全部" });
      this.branchOptions = JSON.parse(JSON.stringify(Items));
    },
  },
  async mounted() {
    await this.getOptions();
    await this.getData();
    
  },

  watch: {},
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: 97vw;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 3060px;
  //   display: grid;

  text-align: center;
  border-right: 0px;
  //   width: 500px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 40px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 11px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #1c1919;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(3, 283px);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px repeat(30, max-content);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  height: 94px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
// ::v-deep.btn-primary {
//   background: #165b9b;
//   border-color: #165b9b;
// }
.big-title2 {
  font-weight: 600;
}

div.sectoion-left
  > div.priorityNum
  > div.priority
  > div.input-group-prepend
  > div.input-group-text {
  background: #fcc41b;
  width: 50px;
  color: #262525;
}
.main-content {
  display: flex;
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 380px;
  background-color: #251c19;
}

/* 左側區域：影片 */
/* 外部容器，控制左側區塊 */
.sectoion-left {
  position: relative;
  width: 65%; /* 左側占比 */
  padding: 10px;
  background-color: #251c19;
}

/* 包裹 YouTube 的容器，設置比例 */
.youtube-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000; /* 背景黑色 */
  overflow: hidden; /* 防止內容溢出 */
}

.youtube-wrapper iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  height: auto; /* 自動調整高度 */
}

/* 右側區域：課程清單 */
.sectoion-right {
  width: 35%;
  height: 100%;
  font-size: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.24);
  color: rgb(255, 255, 255);
  overflow: hidden;
}

.sectoion-right-head {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background: #19191a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}

.sectoion-right-main {
  padding: 0;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 40px); /* 頭部高度已佔用 */
  background: #19191a;
}

.sectoion-right-main > div {
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  height: 34px;
  line-height: 34px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  padding: 0 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.sectoion-right-main > div:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* 通用課程樣式 */
.course-line {
  min-height: 32px;
  display: grid;
  grid-template-columns: 137px 1fr;
}

.course-line-head {
  font-weight: bold;
}

.course-line-content {
  color: #4d4545;
}
.course-line {
  min-height: 32px;
  display: grid;
  grid-template-columns: 137px 1fr;
  .course-line-head {
    font-weight: bolder;
  }
  .course-line-content {
    color: #4d4545;
  }
}

::v-deepoption:disabled {
  // background: red;
  color: red;
}
::v-deepiframe {
  width: 100%;
  height: 100%; /* Also helpful. Optional. */
}
</style>
