<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        在校課程平台維護
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="單位">
          <b-select
            :options="schoolOptions"
            v-model="searchSchool"
            @change="getData"
            value-field="SchoolCode"
            text-field="SchoolName"
            :disabled="healthCodeCheck"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="類別">
          <b-select
            :options="branchOptions"
            v-model="searchBranch"
            value-field="Code"
            text-field="Display"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="課程主旨">
          <b-input
            v-model.trim="searchSubject"
            @keydown.enter="getData"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="課程內容">
          <b-input
            v-model.trim="searchContent"
            @keydown.enter="getData"
          ></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增課程</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding:0.5px !important">
        <b-button
          variant="primary"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >編輯</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click="deleteModal(item)"
          class="mr-2"
          >刪除</b-button
        >
      </div>
      <div :title="item.schoolName">
        {{ item.schoolName || "" }}
      </div>
      <div :title="item.branchName">
        {{ item.branchName || "" }}
      </div>
      <div :title="item.Subject">
        {{ item.Subject || "" }}
      </div>
      <div :title="item.Content">
        {{ item.Content || "" }}
      </div>
      <div :title="item.VideoUrl">
        {{ item.VideoUrl || "" }}
      </div>
      <div :title="item.StartDate">
        {{ item.StartDate ? $twDate2(item.StartDate) : "" }}
      </div>
      <div :title="item.EndDate">
        {{ item.EndDate ? $twDate2(item.EndDate) : "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        class="mt-2"
        :options="rows"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <!-- forSaveData -->
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '編輯'}課程資料`"
        size="lg"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="發布單位" class="one-row">
            <b-select
              :options="schoolOptions.slice(1)"
              v-model="modalItem.Code"
              value-field="SchoolCode"
              text-field="SchoolName"
              :disabled="healthCodeCheck"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="類別" class="one-row">
            <b-select
              :options="branchOptions.slice(1)"
              v-model="modalItem.Branch"
              value-field="Code"
              text-field="Display"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="主旨" class="one-row">
            <b-input v-model="modalItem.Subject"></b-input>
          </b-input-group>
          <b-input-group prepend="內容" class="one-row">
            <b-textarea v-model="modalItem.Content"></b-textarea>
          </b-input-group>
          <b-input-group prepend="影片網址" class="one-row">
            <b-input v-model="modalItem.VideoUrl"></b-input>
          </b-input-group>
          <b-input-group prepend="上線時間">
            <date-picker-tw
              :time="modalItem.StartDate"
              @update="(t) => (modalItem.StartDate = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>

          <b-input-group prepend="下線時間">
            <date-picker-tw
              :time="modalItem.EndDate"
              @update="(t) => (modalItem.EndDate = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>
        </div>
        <div class="modal-grid-file" v-if="modalType == 2">
          <b-input-group prepend="上傳附件" style="grid-column: 1/-1;">
            <div class="custom-file-upload-out">
              <label for="file-upload" class="custom-file-upload">
                <span style="color: rgb(105 36 136);">
                  <i class="fas fa-cloud-upload-alt fa-lg"></i>
                </span>
                <div>Upload File</div>
                <input
                  id="file-upload"
                  type="file"
                  @change="fileChange"
                  ref="file-upload"
                />
              </label>
            </div>
            <b-button
              variant="success"
              @click="uploadFile"
              size="sm"
              class="ml-2"
              style="height: 39px;margin-top:6px"
              >新增附件</b-button
            >
          </b-input-group>

          <div></div>
          <div
            v-for="(itemFile, filekey) in modalItem.Files"
            :key="`modalItemFiles${filekey}`"
            title="刪除"
            class="one-row"
            style="cursor: pointer;"
          >
            <span @click="deleteFile(itemFile)">
              {{ itemFile.DocFile.split("/").pop() }}
              <span style="color:red"><i class="fas fa-times"></i></span
            ></span>
          </div>
          <div class="one-row">
            <span v-if="fileName" style="font-weight:bolder"
              >目前所選擇上要傳的附件: </span
            >{{ fileName }}
          </div>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "發布單位", key: "Code", sortDesc: null },
  { name: "類別", key: "Branch", sortDesc: null },
  { name: "主旨", key: "Subject", sortDesc: null },
  { name: "內容", key: "Content", sortDesc: null },
  { name: "影片網址", key: "VideoUrl", sortDesc: null },
  { name: "上線時間", key: "StartDate", sortDesc: null },
  { name: "下線時間", key: "EndDate", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        Id: "",
        Code: "",
        CodeName: "",
        Branch: "",
        BranchName: "",
        Subject: "",
        Content: "",
        VideoUrl: "",
        StartDate: "",
        EndDate: "",
        Creater: "",
        CreateTime: "",
        Kind: "",
        Files: [],
      },
      fileName: "",
      file: null,
      modalType: 1,

      //search
      searchSchool: null,
      searchBranch: null,
      searchSubject: "",
      searchContent: "",

      //searchOptions or ModalOptions
      schoolOptions: [],
      branchOptions: [],
    };
  },
  components: {
    Treeselect,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },

  methods: {
    async deleteModal(item) {
      const url = `api/Training/${item.Id}`;
      try {
        const res = await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`刪除失敗` + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    fileChange(e) {
      // console.log("e", e);
      this.fileName = e.target.files[0].name;
      this.file = e.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }
      let form = new FormData();
      form.append("input", this.file);

      try {
        const map = await window.axios.post(
          `Training/UploadFile?trainingId=${this.modalItem.Id}`,
          form
        );
        this.getNewItemData();

        this.fileName = "";
        this.file = null;
        this.$bvToast.toast(`新增附件成功!!`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast("新增附件失敗!! " + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteFile(item) {
      try {
        const map = await window.axios.delete(`Training/RemoveFile`, {
          data: {
            SeqNo: item.SeqNo,
          },
        });
        this.getNewItemData();

        this.$bvToast.toast(`刪除附件成功!!`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast("刪除附件失敗!! " + e, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getNewItemData(item) {
      const { Files } = await window.axios.get(
        `api/Training/${this.modalItem.Id}`
      );
      this.modalItem.Files = JSON.parse(JSON.stringify(Files));
    },
    clearSearch() {
      this.searchSchool = null;
      this.searchBranch = null;
      this.searchSubject = "";
      this.searchContent = "";
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      this.modalType = type;
      this.file = null;
      this.fileName = "";
      if (type == 1) {
        this.modalItem = {
          Code: "",
          CodeName: "",
          Branch: "",
          BranchName: "",
          Subject: "",
          Content: "",
          VideoUrl: "",
          StartDate: "",
          EndDate: "",
          Creater: `${window.sessionStorage.docrkkt}`.replace(/\"/g, ""),
          CreateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          Kind: "1",
          Files: [],
        };
      } else {
        this.modalItem = JSON.parse(JSON.stringify(item));
        this.modalItem.StartDate = this.$twDate(item.StartDate);
        this.modalItem.EndDate = this.$twDate(item.EndDate);
      }

      if (this.healthCodeCheck) {
        this.modalItem.Code = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
      }

      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/Training`;

      const obj = {
        ...this.modalItem,
      };
      obj.StartDate = this.$usDate(obj.StartDate);
      obj.EndDate = this.$usDate(obj.EndDate);
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `課程${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async getData() {
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      qs = `${qs}&$filter=Kind eq '1'`;

      let bQs = false;

      if (this.searchSchool) {
        qs = this.$appendSubstringofFilter("Code", qs, this.searchSchool);
      }

      if (this.searchBranch) {
        qs = this.$appendSubstringofFilter("Branch", qs, this.searchBranch);
      }

      if (this.searchSubject) {
        qs = this.$appendSubstringofFilter("Subject", qs, this.searchSubject);
      }

      if (this.searchContent) {
        qs = this.$appendSubstringofFilter("Content", qs, this.searchContent);
      }

      try {
        let { Items, Count } = await window.axios.get(`api/Training${qs}`);

        Items = Items.map((s) => {
          s.schoolName = this.schoolOptions.find((k) => k.SchoolCode == s.Code)
            ? this.schoolOptions.find((k) => k.SchoolCode == s.Code).SchoolName
            : "";
          s.branchName = this.branchOptions.find((k) => k.Code == s.Branch)
            ? this.branchOptions.find((k) => k.Code == s.Branch).Display
            : "";
          return s;
        });

        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getOptions() {
      let schoolOptions = JSON.parse(localStorage.dtcSchoolList);
      schoolOptions.unshift({ SchoolCode: null, SchoolName: "全部" });

      this.schoolOptions = JSON.parse(JSON.stringify(schoolOptions));

      let { Items } = await window.axios.get(
        "api/SysCode?&$filter=Category eq 'branch'"
      );
      Items.unshift({ Code: null, Display: "全部" });
      this.branchOptions = JSON.parse(JSON.stringify(Items));
    },
  },
  async mounted() {
    await this.getOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 130px 219px 110px 400px 400px 320px 130px 130px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    padding-top: 5px !important;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 34px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2,
.modal-grid-file {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  .one-row {
    grid-column: 1/-1;
  }
}
.modal-grid-file {
  grid-template-columns: 123px 1fr 1fr;
  margin-top: 10px;
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  .item-box {
    margin: 5px;
  }
}
.custom-file-upload-out {
  border: 1px solid #ccc;
  padding: 6px 12px;
  .custom-file-upload {
    display: inline-block;
    padding-top: 6px;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;

    input[type="file"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}
</style>
